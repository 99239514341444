var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('v-container',{staticClass:"m-container px-5 px-lg-0 homepage-performance-boost"},[_c('div',{staticClass:"d-flex flex-wrap perks justify-center py-10 py-lg-0"},[_c('div',{staticClass:"d-flex perk"},[_c('div',{staticClass:"d-flex flex-column justify-center perk__text"},[_c('h2',{staticClass:"section__title align-self-center align-self-lg-start"},[_vm._v("\n            "+_vm._s(_vm.block.performancePerk1.perkTitle1)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"section__subtitle"},[_vm._v("\n            "+_vm._s(_vm.block.performancePerk1.perkSubtitle1)+"\n          ")]),_vm._v(" "),_c('NuxtLink',{attrs:{"to":_vm.block?.performancePerk1?.button?.link}},[(_vm.block?.performancePerk3?.button?.text)?_c('div',{staticClass:"d-flex perk__button pt-10"},[_c('v-btn',{attrs:{"depressed":"","aria-label":_vm.block?.performancePerk1?.button?.text,"height":_vm.block?.performancePerk1?.button?.height,"width":_vm.block?.performancePerk1?.button?.width,"color":_vm.block?.performancePerk1?.button?.color,"outlined":_vm.block?.performancePerk1?.button?.outlined}},[_c('span',{style:({
                    color: _vm.block?.performancePerk1?.button?.textColor,
                  }),domProps:{"innerHTML":_vm._s(_vm.block?.performancePerk1?.button?.text)}})])],1):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"d-flex perk__image"},[(_vm.block.performancePerk1.perkImage1)?_c('nuxt-img',{attrs:{"src":_vm.block.performancePerk1.perkImage1 || '',"alt":"perk","loading":"lazy","format":"webp","width":"460"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}})]},proxy:true}],null,false,96476090)}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex perk"},[_c('div',{staticClass:"d-flex perk__image order-1 order-lg-0"},[(_vm.block.performancePerk2.perkImage2)?_c('nuxt-img',{attrs:{"src":_vm.block.performancePerk2.perkImage2 || '',"alt":"perk","loading":"lazy","format":"webp","width":"460"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}})]},proxy:true}],null,false,96476090)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-center perk__text order-0 order-lg-1"},[_c('h2',{staticClass:"section__title align-self-center align-self-lg-start"},[_vm._v("\n            "+_vm._s(_vm.block.performancePerk2.perkTitle2)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"section__subtitle"},[_vm._v("\n            "+_vm._s(_vm.block.performancePerk2.perkSubtitle2)+"\n          ")]),_vm._v(" "),_c('NuxtLink',{attrs:{"to":_vm.block?.performancePerk2?.button?.link}},[(_vm.block?.performancePerk3?.button?.text)?_c('div',{staticClass:"d-flex perk__button pt-10"},[_c('v-btn',{attrs:{"depressed":"","aria-label":_vm.block?.performancePerk2?.button?.text,"height":_vm.block?.performancePerk2?.button?.height,"width":_vm.block?.performancePerk2?.button?.width,"color":_vm.block?.performancePerk2?.button?.color,"outlined":_vm.block?.performancePerk2?.button?.outlined}},[_c('span',{style:({
                    color: _vm.block?.performancePerk2?.button?.textColor,
                  }),domProps:{"innerHTML":_vm._s(_vm.block?.performancePerk2?.button?.text)}})])],1):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex perk"},[_c('div',{staticClass:"d-flex flex-column justify-center perk__text"},[_c('h2',{staticClass:"section__title align-self-center align-self-lg-start"},[_vm._v("\n            "+_vm._s(_vm.block.performancePerk3.perkTitle3)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"section__subtitle"},[_vm._v("\n            "+_vm._s(_vm.block.performancePerk3.perkSubtitle3)+"\n          ")]),_vm._v(" "),_c('NuxtLink',{attrs:{"to":_vm.block?.performancePerk3?.button?.link}},[(_vm.block?.performancePerk3?.button?.text)?_c('div',{staticClass:"d-flex perk__button pt-10"},[_c('v-btn',{attrs:{"depressed":"","aria-label":_vm.block?.performancePerk3?.button?.text,"height":_vm.block?.performancePerk3?.button?.height,"width":_vm.block?.performancePerk3?.button?.width,"color":_vm.block?.performancePerk3?.button?.color,"outlined":_vm.block?.performancePerk3?.button?.outlined,"href":_vm.block?.performancePerk3?.button?.link,"target":"_blank"}},[_c('span',{style:({
                    color: _vm.block?.performancePerk3?.button?.textColor,
                  }),domProps:{"innerHTML":_vm._s(_vm.block?.performancePerk3?.button?.text)}})])],1):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"d-flex perk__image"},[(_vm.block.performancePerk3.perkImage3)?_c('nuxt-img',{attrs:{"src":_vm.block.performancePerk3.perkImage3 || '',"alt":"perk","loading":"lazy","format":"webp","width":"460"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}})]},proxy:true}],null,false,96476090)}):_vm._e()],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }